<template>
	<div id="connect-chat-bot-to-website">
		<span>
			<img class="icon" src="/images/chat-bot/chat-bot.svg" alt="">
		</span>

		<div class="text-container">
			<h5>
				{{ $t('chatBot.onboarding.headline') }}
			</h5>

			<span>
				{{ $t('chatBot.onboarding.description') }}
			</span>

			<div class="mt-2">
				<button @click="createChatBot"
						:disabled="creating"
						class="btn btn-dark btn-md btn-fill">
					<i v-if="creating" class="far fa-spinner-third fa-spin fa-fw me-2" />
					<span>{{ $t('chatBot.integration.button') }}</span>
				</button>
			</div>
		</div>
		<div class="clearfix"></div>
	</div>
</template>

<script>

import ChatBotApiService from '@/services/chat-bot-api/ChatBotApiService';

import {mapGetters} from "vuex";

export default {
	data: () => ({
		creating: false
	}),

	computed: {
		...mapGetters('customer', {
			customer: 'getCustomer'
		}),
	},

	methods: {
		async createChatBot() {
			this.creating = true

			try {
				await ChatBotApiService.create((response) => {
					this.creating = false
					this.$emit('onCreated')
				}, (error) => {
					this.creating = false
					this.$emit('onCreated')
				});

			} catch (error) {
				console.error('An error occurred:', error.message);
			}
		},
	}
};
</script>

<style scoped lang="scss">
#connect-chat-bot-to-website {
	position: relative;
	border-radius: 4px;
	border: 1px solid #dee2e6 !important;
	background-color: #fff;
	padding: 28px 30px;

	.btn {
		background: rgb(18, 55, 76);
	}

	.icon {
		width: 30px;
		height: 30px;
		margin-right: 15px;
	}

	.text-container {
		display: flex;
		flex-direction: column;
		gap: 4px;

		width: calc(100% - 50px);
		float: right;

		h5 {

			font-size: 18px;
			padding: 0;
			margin: 0 0 10px 0;
			line-height: 1.2;
		}
	}

	.move-headline {
		margin-left: 15px;
	}
}
</style>
