<template>
	<div class="questions">
		<template v-if="! question">
			<div class="page-title-wrapper">
				<div>
					<div class="page-title">{{ $t('chatBot.questions.title') }}</div>
					<div class="page-description">{{ $t('chatBot.questions.description') }}</div>
				</div>

				<button type="button" @click="createQuestion = true">
					<i class="far fa-plus fa-fw" />
					{{ $t('chatBot.questions.new') }}
				</button>
			</div>

			<div class="items">
				<div v-if="items !== null"
					 v-for="item in items"
					 :key="`question-${item.id}`"
					 @click="question = item"
					 class="question-wrapper clickable">
					<div class="question">{{ item.question }}</div>
					<div class="answer">{{ item.answer }}</div>

					<i class="fal fa-chevron-right fa-fw" />
				</div>

				<div v-if="items === null"
					 v-for="n in limit"
					 :key="`placeholder-${n}`"
					 class="question-wrapper mock">
					<div class="question">Lorem ipsum dolar sit amet</div>
					<div class="answer">Lorem ipsum dolar sit amet, lorem ipsum dolar sit amet</div>

					<i class="fal fa-chevron-right fa-fw" />
				</div>

				<div v-if="!! pages && pages > 1"
					 class="items-pagination">
					<button type="button"
							:disabled="page === 1"
							@click="page--">
						{{ $t('chatBot.questions.previous') }}
					</button>

					<div class="page">
						{{ $t('chatBot.questions.page', { page, pages }) }}
					</div>

					<button type="button"
							:disabled="page === pages"
							@click="page++">
						{{ $t('chatBot.questions.next') }}
					</button>
				</div>
			</div>
		</template>

		<chat-question v-if="!! question"
					   :question="question"
					   :bot="bot"
					   @back="question = null"
					   @update="load"
		/>

		<chat-bot-create-question v-if="createQuestion"
								  :bot="bot"
								  @create="load"
								  @close="createQuestion = false"
		/>
	</div>
</template>

<style lang="scss" scoped>
.questions {
	display: flex;
	flex-direction: column;
	margin: -20px -30px -30px -30px;

	> div.page-title-wrapper {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 25px;
		margin: 0;
		border-bottom: 1px solid #eee;

		div.page-title {
			color: #000;
			font-size: 22px;
			letter-spacing: -.2px;
			font-weight: 900;
		}

		div.page-description {
			color: #676767;
			font-size: 14px;
			font-weight: 400;
		}

		button {
			display: flex;
			align-items: center;
			gap: 8px;
			color: #fff;
			font-size: 13px;
			font-weight: 500;
			padding: 12px 18px;
			letter-spacing: -.1px;
			border: 0;
			outline: 0;
			box-shadow: none;
			border-radius: 8px;
			background-color: #000;
			margin-left: auto;

			&:disabled {
				opacity: .5;
			}
		}
	}

	> div.items {
		display: flex;
		flex-direction: column;

		> div.items-pagination {
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 20px 25px;

			> div.page {
				color: #5b5b5b;
				font-size: 14px;
				font-weight: 500;
			}

			> button {
				color: #363636;
				font-size: 14px;
				font-weight: 500;
				padding: 8px 10px;
				border-radius: 6px;
				border: 0;
				background-color: #fff;

				&:hover {
					background-color: #ececec;
				}

				&:disabled {
					cursor: not-allowed;
					opacity: .7;
				}
			}
		}

		> div.question-wrapper {
			position: relative;

			&.clickable {
				cursor: pointer;
				user-select: none;

				&:hover {
					background-color: #f3f3f3;
				}
			}

			display: flex;
			flex-direction: column;
			gap: 6px;
			padding: 20px 25px;
			border-bottom: 1px solid #eee;

			> div.question {
				color: #000;
				font-size: 16px;
				font-weight: 600;
			}

			> div.answer {
				color: #484848;
				font-size: 14px;
				font-weight: 400;
				width: calc(100% - 50px);
			}

			> i {
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				width: 80px;
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 18px;
				color: #7a7a7a;
			}
		}
	}
}
</style>

<script>
import ChatBotStatus from './ChatBotStatus'
import ChatBotSetup from '@/app/chat-bot/components/ChatBotSetup'

import ChatBotQuestionService from '@/services/chat-bot-api/ChatBotQuestionService'
import ChatQuestion from '@/app/chat-bot/components/ChatQuestion'
import ChatBotCreateQuestion from "@/app/chat-bot/components/ChatBotCreateQuestion";

export default {
	props: {
		bot: {
			type: Object,
			required: true
		}
	},

	data: () => ({
		items: null,
		limit: 6,
		page: 1,
		total: null,
		question: null,
		createQuestion: false
	}),

	computed: {
		pages() {
			return !! this.total
				? Math.ceil(this.total / this.limit)
				: null
		}
	},

	watch: {
		page() {
			this.load()
		}
	},

	mounted() {
		this.load()
	},

	methods: {
		load() {
			this.items = null

			const limit = this.limit
			const page = this.page

			ChatBotQuestionService.findAll({
				limit,
				page,

				sort: [{
					key: 'created_at',
					direction: 'DESC'
				}],

				chat_bot_id: this.bot.id
			}, ({ data }) => {
				this.items = data.rows
				this.total = data.total
			}, () => {
				// @TODO: Handle error
			})
		}
	},

	components: {
		ChatBotCreateQuestion,
		ChatQuestion,
		ChatBotSetup,
		ChatBotStatus
	}
}
</script>
