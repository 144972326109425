const BaseService = require('@/services/BaseService')

const namespace = 'chat-bots/statistics'

const get = (id, options, cb, errorCb) => {
	BaseService.get(`${namespace}/${id}`, options, cb, errorCb)
}

export default {
	get
}
