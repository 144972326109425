<template>
	<div v-if="!! copy"
		 class="question">
		<div class="back">
			<button type="button" @click="onBack">
				<i class="far fa-chevron-left fa-fw" />
				<span>{{ $t('chatBot.questions.back') }}</span>
			</button>
		</div>

		<div class="fields">
			<div>
				<div class="label">{{ $t('chatBot.questions.question') }}</div>
				<input type="text" v-model="copy.question" :disabled="updating" />
			</div>

			<div>
				<div class="label">{{ $t('chatBot.questions.answer') }}</div>
				<textarea v-model="copy.answer" :disabled="updating"></textarea>
			</div>
		</div>

		<div class="controls">
			<button type="button"
					:disabled="! canUpdate"
					@click="onDelete"
					class="remove">
				<i v-if="! deleting" class="far fa-trash fa-fw" />
				<i v-else class="far fa-spinner-third fa-spin fa-fw" />
				{{ $t('chatBot.questions.delete') }}
			</button>

			<button type="button"
					:disabled="! canUpdate"
					@click="onSave"
					class="update">
				<i v-if="updating" class="far fa-spinner-third fa-spin fa-fw" />
				{{ $t('chatBot.questions.save') }}
			</button>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.question {
	overflow-y: scroll;
	height: 600px;
	max-height: calc(100vh - 100px);

	> div.back {
		position: sticky;
		top: 0;
		width: 100%;
		z-index: 99;
		padding: 10px 20px;
		background-color: #fff;
		border-bottom: 1px solid #ccc;

		button {
			color: #363636;
			font-size: 14px;
			font-weight: 500;
			padding: 8px 10px;
			border-radius: 6px;
			border: 0;
			background-color: #fff;

			&:hover {
				background-color: #ececec;
			}
		}
	}

	> div.fields {
		display: flex;
		flex-direction: column;
		padding: 10px 30px;
		gap: 10px;

		> div {
			display: flex;
			flex-direction: column;

			> div.label {
				color: #282828;
				font-weight: 500;
				letter-spacing: -.1px;
				font-size: 13px;
				text-align: left;
				padding: 10px 0;
			}

			input, textarea {
				color: #000 !important;
				padding: 10px !important;
				font-size: 14px;
				font-weight: 400;
				border: 1px solid #ccc;
				outline: 0;
				box-shadow: none;
				width: 100%;
				border-radius: 6px;
			}

			textarea {
				min-height: 300px;
			}
		}
	}

	> div.controls {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		padding: 10px 30px;
		gap: 10px;

		> button {
			display: flex;
			align-items: center;
			gap: 8px;
			color: #fff;
			font-size: 13px;
			font-weight: 500;
			padding: 12px 18px;
			letter-spacing: -.1px;
			border: 0;
			outline: 0;
			box-shadow: none;
			border-radius: 8px;
			background-color: #000;

			&.remove {
				background-color: #9d1818;
			}

			&:disabled {
				opacity: .5;
			}
		}
	}
}
</style>

<script>
import ChatBotQuestionService from '@/services/chat-bot-api/ChatBotQuestionService'

export default {
	props: {
		question: {
			type: Object,
			required: true
		}
	},

	data: () => ({
		deleting: false,
		updating: false,
		copy: null
	}),

	computed: {
		canUpdate() {
			return ! this.deleting && ! this.updating
		}
	},

	mounted() {
		this.copy = JSON.parse(JSON.stringify(this.question))
	},

	methods: {
		onDelete() {
			if (! this.canUpdate) {
				return
			}

			this.deleting = true

			ChatBotQuestionService.remove(
				this.copy.id,
				{},
				() => {
					this.deleting = false
					this.onUpdate()
				},
				() => {
					this.deleting = false
					this.onUpdate()
				}
			)
		},

		onSave() {
			if (! this.canUpdate) {
				return
			}

			this.updating = true

			ChatBotQuestionService.update(
				this.copy.id,
				{
					question: this.copy.question,
					answer: this.copy.answer
				},
				() => {
					this.updating = false
					this.onUpdate()
				},
				() => {
					this.updating = false
					this.onUpdate()
				}
			)
		},

		onUpdate() {
			this.$emit('update')
			this.onBack()
		},

		onBack() {
			this.$emit('back')
		}
	}
}
</script>
