<template>
	<modern-modal @close="onClose">
		<div slot="header">
			<div class="title">{{ $t('chatBot.questions.create.title') }}</div>
			<div class="subtitle">{{ $t('chatBot.questions.create.subtitle') }}</div>
		</div>

		<div slot="body">
			<div class="fields">
				<div>
					<div class="label">{{ $t('chatBot.questions.question') }}</div>
					<input type="text" v-model="question" :disabled="creating" />
				</div>

				<div>
					<div class="label">{{ $t('chatBot.questions.answer') }}</div>
					<textarea v-model="answer" :disabled="creating"></textarea>
				</div>
			</div>
		</div>

		<div slot="footer">
			<button type="button"
					:disabled="! canSubmit"
					@click="onCreate"
					class="update">
				<i v-if="creating" class="far fa-spinner-third fa-spin fa-fw" />
				{{ $t('chatBot.questions.save') }}
			</button>
		</div>
	</modern-modal>
</template>

<style lang="scss" scoped>
div.fields {
	display: flex;
	flex-direction: column;
	gap: 10px;

	> div {
		display: flex;
		flex-direction: column;

		> div.label {
			color: #282828;
			font-weight: 500;
			letter-spacing: -.1px;
			font-size: 13px;
			text-align: left;
			padding: 10px 0;
		}

		input, textarea {
			color: #000 !important;
			padding: 10px !important;
			font-size: 14px;
			font-weight: 400;
			border: 1px solid #ccc;
			outline: 0;
			box-shadow: none;
			width: 100%;
			border-radius: 6px;
		}

		textarea {
			min-height: 150px;
		}
	}
}

button {
	display: flex;
	align-items: center;
	gap: 8px;
	color: #fff;
	font-size: 13px;
	font-weight: 500;
	padding: 12px 18px;
	letter-spacing: -.1px;
	border: 0;
	outline: 0;
	box-shadow: none;
	border-radius: 8px;
	background-color: #000;
	margin-left: auto;

	&:disabled {
		opacity: .5;
	}
}
</style>

<script>
import ChatBotQuestionService from '@/services/chat-bot-api/ChatBotQuestionService'

import ModernModal from '@/app/layout/components/ModernModal'

export default {
	props: {
		bot: {
			type: Object,
			required: true
		}
	},
	data: () => ({
		question: '',
		answer: '',
		creating: false
	}),

	computed: {
		canSubmit() {
			return ! this.creating && String(this.question || '').length > 0 && String(this.answer || '').length > 0
		}
	},

	methods: {
		onCreate() {
			if (! this.canSubmit) {
				return
			}

			this.creating = true

			ChatBotQuestionService.create({
				question: this.question,
				answer: this.answer,
				bot_id: this.bot.id
			}, () => {
				this.creating = false
				this.afterCreated()
			}, () => {
				this.creating = false
				this.afterCreated()
			})
		},

		afterCreated() {
			this.$emit('create')
			this.onClose()
		},

		onClose() {
			this.$emit('close')
		}
	},

	components: {
		ModernModal
	}
}
</script>
