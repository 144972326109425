<template>
	<div class="conversations">
		<template v-if="! conversation">
			<div class="page-title-wrapper">
				<div class="page-title">{{ $t('chatBot.conversations.title') }}</div>
				<div class="page-description">{{ $t('chatBot.conversations.description') }}</div>
			</div>

			<div class="items">
				<div v-if="items !== null"
					 v-for="item in items"
					 :key="`conversation-${item.id}`"
					 @click="conversation = item.id"
					 class="conversation clickable">
					<div class="date">{{ item.created_at | moment('LLL') }}</div>
					<div class="messages">
						<i class="far fa-message fa-fw" />
						<span>{{ $tc('chatBot.conversations.messages', item.messagesCount, { count: item.messagesCount }) }}</span>
					</div>

					<i class="fal fa-chevron-right fa-fw" />
				</div>

				<div v-if="items === null"
					 v-for="n in limit"
					 :key="`placeholder-${n}`"
					 class="conversation mock">
					<div class="date">21. marts kl. 16:19</div>
					<div class="messages">
						xx
						<span>{{ $tc('chatBot.conversations.messages', 20, { count: 20 }) }}</span>
					</div>

					<i class="fal fa-chevron-right fa-fw" />
				</div>

				<div v-if="!! pages && pages > 1"
					 class="items-pagination">
					<button type="button"
							:disabled="page === 1"
							@click="page--">
						{{ $t('chatBot.conversations.previous') }}
					</button>

					<div class="page">
						{{ $t('chatBot.conversations.page', { page, pages }) }}
					</div>

					<button type="button"
							:disabled="page === pages"
							@click="page++">
						{{ $t('chatBot.conversations.next') }}
					</button>
				</div>
			</div>
		</template>

		<chat-conversation v-if="!! conversation"
						   :id="conversation"
						   :bot="bot"
						   @back="conversation = null"
		/>
	</div>
</template>

<style lang="scss" scoped>
.conversations {
	display: flex;
	flex-direction: column;
	margin: -20px -30px -30px -30px;

	> div.page-title-wrapper {
		padding: 25px;
		margin: 0;
		border-bottom: 1px solid #eee;

		> div.page-title {
			color: #000;
			font-size: 22px;
			letter-spacing: -.2px;
			font-weight: 900;
		}

		> div.page-description {
			color: #676767;
			font-size: 14px;
			font-weight: 400;
		}
	}

	> div.items {
		display: flex;
		flex-direction: column;

		> div.items-pagination {
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 20px 25px;

			> div.page {
				color: #5b5b5b;
				font-size: 14px;
				font-weight: 500;
			}

			> button {
				color: #363636;
				font-size: 14px;
				font-weight: 500;
				padding: 8px 10px;
				border-radius: 6px;
				border: 0;
				background-color: #fff;

				&:hover {
					background-color: #ececec;
				}

				&:disabled {
					cursor: not-allowed;
					opacity: .7;
				}
			}
		}

		> div.conversation {
			position: relative;

			&.clickable {
				cursor: pointer;
				user-select: none;

				&:hover {
					background-color: #f3f3f3;
				}
			}

			display: flex;
			flex-direction: column;
			gap: 6px;
			padding: 20px 25px;
			border-bottom: 1px solid #eee;

			> div.date {
				color: #000;
				font-size: 16px;
				font-weight: 600;
			}

			> div.messages {
				display: flex;
				align-items: center;
				gap: 6px;

				color: #7a7a7a;
				font-size: 12px;
				font-weight: 400;
			}

			> i {
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				width: 80px;
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 18px;
				color: #7a7a7a;
			}
		}
	}
}
</style>

<script>
import ChatBotStatus from './ChatBotStatus'
import ChatBotSetup from '@/app/chat-bot/components/ChatBotSetup'

import ChatBotConversationService from '@/services/chat-bot-api/ChatBotConversationService'
import ChatConversation from "@/app/chat-bot/components/ChatConversation";

export default {
	props: {
		bot: {
			type: Object,
			required: true
		}
	},

	data: () => ({
		items: null,
		limit: 6,
		page: 1,
		total: null
	}),

	computed: {
		pages() {
			return !! this.total
				? Math.ceil(this.total / this.limit)
				: null
		},

		conversation: {
			get() {
				return this.$route.query.conversation || null
			},

			set(conversation) {
				this.$router.push({
					...this.$route,
					query: {
						...this.$route.query || {},
						conversation: conversation || undefined
					}
				})
			}
		}
	},

	watch: {
		page() {
			this.load()
		}
	},

	mounted() {
		this.load()
	},

	methods: {
		load() {
			this.items = null

			const limit = this.limit
			const page = this.page

			ChatBotConversationService.findAll({
				limit,
				page,

				sort: [{
					key: 'created_at',
					direction: 'DESC'
				}],

				chat_bot_id: this.bot.id
			}, ({ data }) => {
				this.items = data.rows
				this.total = data.total
			}, () => {
				// @TODO: Handle error
			})
		}
	},

	components: {
		ChatConversation,
		ChatBotSetup,
		ChatBotStatus
	}
}
</script>
