<template>
	<has-subscription
		feature="ai.chatbot"
		:title="$t('chatBot.teaser.headline')"
		:description="$t('chatBot.teaser.description')"
	>
		<div v-if="! loaded" class="loader">
			<div class="icon">
				<i class="far fa-spinner-third fa-spin" />
			</div>
			<span>{{ $t('chatBot.loading') }}</span>
		</div>

		<template v-if="loaded">
			<landing-page v-if="! bot"
						  :headline="$t('chatBot.onboarding.headline')"
						  :description="$t('chatBot.onboarding.description')"
						  image="/images/chat-bot/teaser.png">

				<!-- Connect Chat Bot to Website -->
				<connect-chat-bot-to-website @onCreated="loadBot" />
			</landing-page>

			<div v-if="!! bot"
				 class="view-wrapper">
				<tab-navigation>
					<ul>
						<li>
							<a href="#"
							   @click.stop.prevent="page = 'overview'"
							   :class="{ active: page === 'overview' }">
								<i class="far fa-list fa-fw me-2" />
								<span>{{ $t('chatBot.navigation.overview') }}</span>
							</a>
						</li>
						<li>
							<a href="#"
							   @click.stop.prevent="page = 'conversations'"
							   :class="{ active: page === 'conversations' }">
								<i class="far fa-messages fa-fw me-2" />
								<span>{{ $t('chatBot.navigation.conversations') }}</span>
							</a>
						</li>
						<li>
							<a href="#"
							   @click.stop.prevent="page = 'questions'"
							   :class="{ active: page === 'questions' }">
								<i class="far fa-question-circle fa-fw me-2" />
								<span>{{ $t('chatBot.navigation.questions') }}</span>
							</a>
						</li>
						<li>
							<a href="#"
							   @click.stop.prevent="page = 'settings'"
							   :class="{ active: page === 'settings' }">
								<i class="far fa-cog fa-fw me-2" />
								<span>{{ $t('chatBot.navigation.settings') }}</span>
							</a>
						</li>
					</ul>

					<div class="clearfix"></div>

					<hr />

					<chat-bot-overview v-if="page === 'overview'"
									   :bot="bot"
					/>

					<chat-bot-conversations v-if="page === 'conversations'"
									   	    :bot="bot"
					/>

					<chat-bot-suggestions v-if="page === 'suggestions'"
										  :bot="bot"
					/>

					<chat-bot-questions v-if="page === 'questions'"
									:bot="bot"
									@updated="loadBot"
					/>

					<chat-bot-settings v-if="page === 'settings'"
									   :bot="bot"
									   @updated="loadBot"
					/>
				</tab-navigation>
			</div>
		</template>
	</has-subscription>
</template>

<style lang="scss" scoped>
.view-wrapper {
	max-width: 1000px;
	margin: 40px auto;
}

div.loader {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 10px;
	padding: 40px 25px;
	border-radius: 16px;
	flex-basis: calc(100% - 10px);
	box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
	background-color: #fff;

	max-width: 1000px;
	margin: 40px auto;

	div.icon {
		font-size: 26px;
	}

	span {
		color: #494949;
		font-size: 14px;
	}
}
</style>

<script>

import { mapGetters } from 'vuex'

import feature from '@/mixins/billing/feature'

import HasSubscription from '@/app/billing/components/HasSubscription'
import LandingPage from '@/app/onboarding/components/LandingPage'
import TopBar from '@/app/shared/global/template/TopBar'

import ConnectChatBotToWebsite from '@/app/chat-bot/components/ConnectChatBotToWebsite'

import ChatBotApiService from '@/services/chat-bot-api/ChatBotApiService'

import TabNavigation from '@/app/layout/components/TabNavigation'

import ChatBotOverview from '../components/ChatBotOverview'
import ChatBotSuggestions from '../components/ChatBotSuggestions'
import ChatBotSettings from '../components/ChatBotSettings'
import ChatBotConversations from "@/app/chat-bot/components/ChatBotConversations";
import ChatBotQuestions from '@/app/chat-bot/components/ChatBotQuestions'

export default {
	mixins: [
		feature
	],

	data: () => ({
		bot: null,
		loaded: false,

		timeout: null
	}),

	computed: {
		...mapGetters('customer', {
			customer: 'getCustomer'
		}),

		page: {
			get() {
				return this.$route.query.page || 'overview'
			},

			set(page) {
				this.$router.push({
					...this.$route,
					query: {
						...this.$route.query || {},
						page
					}
				})
			}
		}
	},

	mounted() {
		this.loadBot()
	},

	beforeDestroy() {
		this.clearTimeout()
	},

	methods: {
		loadBot() {
			this.clearTimeout()

			ChatBotApiService.findAll({}, ({ data }) => {
				this.bot = data.rows[0] || null
				this.loaded = true

				if (!! this.bot && ! ['completed', 'failed'].includes(this.bot.status)) {
					this.timeout = setTimeout(this.loadBot, 5000)
				}
			}, () => {
				// @TODO: Error handling
				this.loaded = true
			})
		},

		clearTimeout() {
			!! this.timeout && clearTimeout(this.timeout)
		}
	},

	components: {
		ChatBotQuestions,
		ChatBotConversations,
		TopBar,
		LandingPage,
		HasSubscription,
		ConnectChatBotToWebsite,
		TabNavigation,
		ChatBotOverview,
		ChatBotSuggestions,
		ChatBotSettings
	}
}
</script>
