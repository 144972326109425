<template>
	<div class="conversation">
		<div class="back">
			<button type="button" @click="back">
				<i class="far fa-chevron-left fa-fw" />
				<span>{{ $t('chatBot.conversations.back') }}</span>
			</button>
		</div>

		<div class="messages">
			<div v-if="!! messages"
				 v-for="message in messages"
				 :class="{ me: message.chat_user_id !== null }"
				 class="message">
				<span>{{ name(message) }} &bull; {{ message.created_at | moment('lll') }}</span>
				<div v-html="message.message" />
			</div>
		</div>

		<div v-if="! completed"
			 class="text-center p-4"
			 v-infinite-scroll="load"
			 infinite-scroll-disabled="busy"
			 infinite-scroll-distance="10">
			<i class="far fa-spinner-third fa-spin fa-fw" />
		</div>
	</div>
</template>

<style lang="scss" scoped>
.conversation {
	overflow-y: scroll;
	height: 600px;
	max-height: calc(100vh - 100px);

	> div.back {
		position: sticky;
		top: 0;
		width: 100%;
		z-index: 99;
		padding: 10px 20px;
		background-color: #fff;
		border-bottom: 1px solid #ccc;

		button {
			color: #363636;
			font-size: 14px;
			font-weight: 500;
			padding: 8px 10px;
			border-radius: 6px;
			border: 0;
			background-color: #fff;

			&:hover {
				background-color: #ececec;
			}
		}
	}

	> div.messages {
		padding: 40px;
		display: flex;
		flex-direction: column;
		gap: 20px;

		> div.message {
			max-width: 60%;
			align-self: flex-start;

			display: flex;
			flex-direction: column;
			align-items: flex-start;
			gap: 2px;

			> span {
				color: #3a3a3a;
				font-size: 11px;
				font-weight: 400;
			}

			> div {
				color: #000;
				font-size: 14px;
				padding: 10px;
				border-radius: 8px;
				background-color: #e5e5e5;
			}

			&.me {
				align-self: flex-end;

				align-items: flex-end;

				> div {
					color: #fff;
					background-color: #0944c5;
				}
			}
		}
	}
}
</style>

<script>
import ChatBotMessageService from '@/services/chat-bot-api/ChatBotMessageService'

import VueInfiniteScroll from 'vue-infinite-scroll'

export default {
	props: {
		id: {
			required: true
		},

		bot: {
			type: Object,
			required: true
		}
	},

	data: () => ({
		messages: null,
		busy: false,
		completed: false
	}),

	methods: {
		load() {
			this.busy = true

			const messages = this.messages || []

			let after = !! messages.length
				? messages[messages.length - 1].id
				: null

			ChatBotMessageService.findAll({
				sort: [{
					key: 'id',
					direction: 'ASC'
				}],

				limit: 6,

				after,

				conversation_id: this.id,

				chat_bot_id: this.bot.id
			}, ({ data }) => {

				this.messages = [
					...messages || [],
					...data.rows
				]

				if (data.rows.length === 0) {
					this.completed = true
					return
				}

				this.busy = false
			}, () => {
				this.busy = false
			})
		},

		back() {
			this.$emit('back')
		},

		name(message) {
			return !! message.chat_user_id
				? this.$t('chatBot.conversations.client')
				: this.$t('chatBot.conversations.bot')
		}
	},

	components: {
		VueInfiniteScroll
	}
}
</script>
