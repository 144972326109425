const BaseService = require('@/services/BaseService')

const namespace = 'chat-bots/bots'

const find = (id, options, cb, errorCb) => {
	BaseService.get(`${namespace}/${id}`, options, cb, errorCb)
}

const findAll = (options, cb, errorCb) => {
	BaseService.get(namespace, options, cb, errorCb)
}

const create = (options, cb, errorCb) => {
	BaseService.post(namespace, options, cb, errorCb);
}

const put = (id, options, cb, errorCb) => {
	BaseService.put(`${namespace}/${id}`, options, cb, errorCb)
}

const remove = (id, cb, errorCb) => {
	BaseService.remove(`${namespace}/${id}`, {}, cb, errorCb)
}

export default {
	find,
	findAll,
	create,
	put,
	remove
}
