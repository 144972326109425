<template>
	<form @submit.prevent="onSave">
		<div class="item">
			<div class="heading">
				<div class="headline">{{ $t('chatBot.settings.language.title') }}</div>
				<div class="description">{{ $t('chatBot.settings.language.description') }}</div>
			</div>

			<div class="input">
				<select v-model="internal.locale" class="form-control">
					<option value="da">Dansk</option>
					<option value="de">Deutsch</option>
					<option value="en">English</option>
					<option value="fi">Suomi</option>
					<option value="sv">Svenska</option>
				</select>
			</div>
		</div>

		<div class="item">
			<div class="heading">
				<div class="headline">{{ $t('chatBot.settings.position.title') }}</div>
				<div class="description">{{ $t('chatBot.settings.position.description') }}</div>
			</div>

			<div class="position-selector">
				<div>
					<div v-for="position in positions"
						 :key="`position-${position}`"
						 :class="[position, internal.position === position ? 'active' : null]"
						 @click="internal.position = position"
					/>
				</div>
			</div>
		</div>

		<div class="item">
			<div class="heading">
				<div class="headline">{{ $t('chatBot.settings.theme.title') }}</div>
				<div class="description">{{ $t('chatBot.settings.theme.description') }}</div>
			</div>

			<div class="themes">
				<div v-for="theme in themes"
					 :key="`theme-${theme}`"
					 :class="[theme, internal.theme === theme ? 'active' : null]"
					 @click="internal.theme = theme"
				/>

				<div :class="{ active: ! themes.includes(internal.theme) }"
					 @click="internal.theme = '0-0%-0%'">
					<i class="far fa-paint-roller" />
				</div>
			</div>

			<template v-if="! themes.includes(internal.theme)" class="color-picker">
				<div class="heading">
					<div class="headline">{{ $t('chatBot.settings.color.title') }}</div>
					<div class="description">{{ $t('chatBot.settings.color.description') }}</div>
				</div>

				<ColorPicker v-model="color"
							 colorFormat="hsl"
							 class="picker"
				/>
			</template>
		</div>

		<div class="item">
			<div class="heading">
				<div class="headline">{{ $t('chatBot.settings.supportEmail.title') }}</div>
				<div class="description">{{ $t('chatBot.settings.supportEmail.description') }}</div>
			</div>

			<div class="input">
				<input type="email"
					   v-model="internal.support_email"
				/>

				<button v-if="String(internal.support_email || '').length > 0"
						type="button"
						@click="internal.support_email = null">
					<i class="far fa-remove fa-fw" />
				</button>
			</div>
		</div>

		<div>
			<button type="submit"
					:disabled="saving">
				<i v-if="saving" class="fas fa-spinner-third fa-spin fa-fw" />
				{{ $t('chatBot.settings.save') }}
			</button>
		</div>
	</form>
</template>

<style lang="scss" scoped>
.input {
	position: relative;

	> input {
		width: 100%;
		border: 1px solid #ccc;
		border-radius: 8px;
		padding: 10px;
	}

	> button {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		z-index: 2;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 0 15px;
		font-size: 12px;
		outline: 0;
		box-shadow: none;
		border: 0;
		background-color: transparent;
	}
}

form {
	display: flex;
	flex-direction: column;
	gap: 20px;

	button[type="submit"] {
		display: flex;
		align-items: center;
		gap: 10px;
		color: #fff;
		padding: 10px 15px;
		border-radius: 6px;
		border: 0;
		font-size: 13px;
		font-weight: 500;
		letter-spacing: -.1p;
		background-color: #000 !important;
		margin-top: 10px;

		&:disabled {
			opacity: .7;
		}
	}

	> div.item {
		display: flex;
		flex-direction: column;
		gap: 10px;

		> div.heading {
			> div.headline {
				color: #000;
				font-size: 16px;
				font-weight: 500;
			}

			> div.description {
				color: #5d5d5d;
				font-size: 14px;
				font-weight: 400;
			}
		}

		.picker {
			position: relative;
			width: 40px;
			height: 40px;
			border: 1px solid #eee;
			border-radius: 8px;
			overflow: hidden;

			&:after {
				position: absolute;
				content: '';

				height: calc(50% - 2px);

				top: 2px;
				right: 2px;
				left: 2px;

				border-top-right-radius: 6px;
				border-top-left-radius: 6px;

				background-color: #fff;

				z-index: 2;

				opacity: .4;
			}
		}

		> div.themes {
			display: flex;
			flex-wrap: wrap;
			gap: 10px;

			> div {
				position: relative;
				width: 40px;
				height: 40px;
				border: 1px solid #eee;
				border-radius: 8px;

				opacity: .7;

				cursor: pointer;
				user-select: none;

				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 20px;

				> * {
					position: relative;
					z-index: 10;
				}

				&:hover {
					opacity: 1;
					border-color: #ccc;
				}

				&.active {
					opacity: 1;
					border-color: #000;
				}

				&:before {
					position: absolute;
					content: '';

					top: 2px;
					right: 2px;
					bottom: 2px;
					left: 2px;

					border-radius: 6px;

					background-color: #ccc;

					z-index: 1;
				}

				&.blue {
					&:before {
						background-color: #0033ff;
					}
				}

				&.red {
					&:before {
						background-color: #ff0000;
					}
				}

				&.orange {
					&:before {
						background-color: #ff8000;
					}
				}

				&.green {
					&:before {
						background-color: #31b414;
					}
				}

				&.purple {
					&:before {
						background-color: #6600ff;
					}
				}

				&:after {
					position: absolute;
					content: '';

					height: calc(50% - 2px);

					top: 2px;
					right: 2px;
					left: 2px;

					border-top-right-radius: 6px;
					border-top-left-radius: 6px;

					background-color: #fff;

					z-index: 2;

					opacity: .4;
				}
			}
		}

		> div.position-selector {
			position: relative;
			width: 70px;
			height: 70px;
			border: 2px solid #595959;
			border-radius: 16px;

			> div {
				position: absolute;
				top: 2px;
				right: 2px;
				bottom: 2px;
				left: 2px;
				border-radius: 14px;
				overflow: hidden;

				> div {
					position: absolute;

					width: 20px;
					height: 20px;
					border-radius: 4px;

					background-color: #dedede;

					cursor: pointer;
					user-select: none;

					&:hover {
						background-color: #a2a2a2;
					}

					&.active {
						background-color: #000 !important;
					}

					&.top_left {
						top: 0;
						left: 0;
					}

					&.top_right {
						top: 0;
						right: 0;
					}

					&.bottom_right {
						bottom: 0;
						right: 0;
					}

					&.bottom_left {
						bottom: 0;
						left: 0;
					}
				}
			}
		}
	}
}
</style>

<script>
import ChatBotApiService from '../../../services/chat-bot-api/ChatBotApiService'

import { component } from '@duoa/vue-color-picker'
import '@duoa/vue-color-picker/dist/vue-color-picker.css'

export default {
	props: {
		bot: {
			type: Object,
			required: true
		}
	},

	data: () => ({
		saving: false,
		internal: null,
		themes: ['blue', 'red', 'orange', 'green', 'purple'],
		positions: ['top_left', 'top_right', 'bottom_right', 'bottom_left']
	}),

	computed: {
		color: {
			get() {
				const color = this.internal.theme

				if (! color || this.themes.includes(color)) {
					return `hsl(0, 0%, 0%)`
				}

				const parts = color.split('-')

				return `hsl(${parts[0]}, ${parts[1]}, ${parts[2]})`
			},

			set(value) {
				if (String(value).match(/hsl\((.+), (.+), (.+)\)/)) {
					const parts = String(value).match(/hsl\((.+), (.+), (.+)\)/)
					value = `${parts[1]}-${parts[2]}-${parts[3]}`
				}

				this.$set(this.internal, 'theme', value)
			}
		}
	},

	watch: {
		bot() {
			this.initialize()
		}
	},

	mounted() {
		this.initialize()
	},

	methods: {
		initialize() {
			this.internal = JSON.parse(JSON.stringify(this.bot))
		},

		onSave() {
			if (this.saving) {
				return
			}

			this.saving = true

			ChatBotApiService.put(
				this.bot.id,
				{
					locale: this.internal.locale,
					theme: this.internal.theme,
					position: this.internal.position,
					support_email: this.internal.support_email
				},
				() => {
					this.saving = false
					this.$emit('updated')
				},
				() => {
					this.saving = false
				}
			)
		}
	},

	components: {
		ColorPicker: component
	}
}
</script>
