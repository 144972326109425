<template>
	<div class="overview">
		<div class="page-title-wrapper">
			<div class="page-title">{{ $t('chatBot.overview.title') }}</div>
			<div class="page-description">{{ $t('chatBot.overview.description') }}</div>
		</div>

		<chat-bot-status :bot="bot" />

		<div class="metrics">
			<div class="metric">
				<div class="metric-label">{{ $t('chatBot.overview.conversations') }}</div>
				<div class="metric-value">
					<template v-if="!! statistics">
						<div class="number-value">
							<div class="number">{{ statistics.conversations.last_7_days }}</div>
							<div class="number-description">{{ $t('chatBot.overview.last_7_days') }}</div>
						</div>
						<div class="number-value">
							<div class="number">{{ statistics.conversations.last_30_days }}</div>
							<div class="number-description">{{ $t('chatBot.overview.last_30_days') }}</div>
						</div>
						<div class="number-value">
							<div class="number">{{ statistics.conversations.total }}</div>
							<div class="number-description">{{ $t('chatBot.overview.total') }}</div>
						</div>
					</template>

					<template v-else>
						<div class="number-value mock">
							<div class="number">47</div>
							<div class="number-description">abcdef</div>
						</div>
						<div class="number-value mock">
							<div class="number">47</div>
							<div class="number-description">abcdef</div>
						</div>
						<div class="number-value mock">
							<div class="number">47</div>
							<div class="number-description">abcdef</div>
						</div>
					</template>
				</div>
			</div>

			<div class="metric">
				<div class="metric-label">{{ $t('chatBot.overview.messages') }}</div>
				<div class="metric-value">
					<template v-if="!! statistics">
						<div class="number-value">
							<div class="number">{{ statistics.messages.last_7_days }}</div>
							<div class="number-description">{{ $t('chatBot.overview.last_7_days') }}</div>
						</div>
						<div class="number-value">
							<div class="number">{{ statistics.messages.last_30_days }}</div>
							<div class="number-description">{{ $t('chatBot.overview.last_30_days') }}</div>
						</div>
						<div class="number-value">
							<div class="number">{{ statistics.messages.total }}</div>
							<div class="number-description">{{ $t('chatBot.overview.total') }}</div>
						</div>
					</template>

					<template v-else>
						<div class="number-value mock">
							<div class="number">47</div>
							<div class="number-description">abcdef</div>
						</div>
						<div class="number-value mock">
							<div class="number">47</div>
							<div class="number-description">abcdef</div>
						</div>
						<div class="number-value mock">
							<div class="number">47</div>
							<div class="number-description">abcdef</div>
						</div>
					</template>
				</div>
			</div>
		</div>

		<chat-bot-setup />
	</div>
</template>

<style lang="scss" scoped>
.overview {
	display: flex;
	flex-direction: column;
	gap: 30px;
	padding: 25px;

	> div.page-title-wrapper {
		> div.page-title {
			color: #000;
			font-size: 22px;
			letter-spacing: -.2px;
			font-weight: 900;
		}

		> div.page-description {
			color: #676767;
			font-size: 14px;
			font-weight: 400;
		}
	}

	> div.metrics {
		display: flex;
		flex-wrap: wrap;
		gap: 20px;

		> div.metric {
			border-radius: 16px;
			flex-basis: calc(50% - 10px);
			box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;

			display: flex;
			flex-direction: column;
			gap: 6px;

			> div.metric-label {
				color: #232323;
				font-size: 16px;
				font-weight: 500;
				padding: 15px 20px;
				border-bottom: 1px solid #eee;
			}

			> div.metric-value {
				flex: 1;

				display: flex;
				align-items: center;

				> div {
					position: relative;
					flex: 1;

					&.number-value {
						display: flex;
						flex-direction: column;
						align-items: center;
						justify-content: center;
						gap: 4px;

						padding: 10px 15px 20px 10px;

						> div.number {
							color: #000;
							font-size: 20px;
							font-weight: 800;
						}

						> div.number-description {
							color: #494949;
							font-size: 12px;
							font-weight: 300;
						}

						&:after {
							position: absolute;
							right: 0;
							top: 50%;
							transform: translateY(-50%);
							content: '';
							width: 1px;
							height: 18px;
							background-color: #ccc;
						}

						&:last-child:after {
							display: none;
						}
					}
				}
			}
		}
	}
}

@media(max-width: 750px) {
	.overview {
		> div.metrics {
			> div.metric {
				flex-basis: 100%;
			}
		}
	}
}
</style>

<script>
import ChatBotStatus from './ChatBotStatus'
import ChatBotSetup from '@/app/chat-bot/components/ChatBotSetup'

import ChatBotStatisticsService from '@/services/chat-bot-api/ChatBotStatisticsService'

export default {
	props: {
		bot: {
			type: Object,
			required: true
		}
	},

	data: () => ({
		statistics: null,
	}),

	mounted() {
		this.load()
	},

	methods: {
		load() {
			ChatBotStatisticsService.get(this.bot.id, {}, ({ data }) => {
				this.statistics = data
			})
		}
	},

	components: {
		ChatBotSetup,
		ChatBotStatus
	}
}
</script>
