<template>
	<div class="status" :class="status">
		<div class="icon">
			<i :class="icon" class="fa-fw" />
		</div>

		<div class="value">
			<div class="title"
				 v-text="title"
			/>

			<div class="description"
				 v-text="description"
			/>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.status {
	display: flex;
	align-items: center;
	gap: 15px;

	padding: 15px;

	border: 1px solid #dcdcdc;
	border-radius: 16px;

	> div.value {
		flex: 1;
	}

	> div.icon {
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 18px;
		width: 40px;
		height: 40px;
		min-width: 40px;
		min-height: 40px;
		border-radius: 6px;
		border: 1px solid #ccc;
		background-color: #f1f1f1;
	}

	> div.value {
		display: flex;
		flex-direction: column;

		> div.title {
			font-size: 14px;
			font-weight: 500;
		}

		> div.description {
			font-size: 13px;
			font-weight: 400;
		}
	}

	&.pending {
		> div.icon {
			color: #2d2d2d;
			border-color: #818181;
			background-color: #f3f3f3;
		}

		> div.value {
			> div.title {
				color: #2d2d2d;
			}

			> div.description {
				color: #595959;
			}
		}
	}

	&.running {
		> div.icon {
			color: #a27402;
			border-color: #d09e21;
			background-color: #ffeed0;
		}

		> div.value {
			> div.title {
				color: #946f09;
			}
		}
	}

	&.completed {
		> div.icon {
			color: #12a202;
			border-color: #33d021;
			background-color: #d0ffd1;
		}

		> div.value {
			> div.title {
				color: #229409;
			}
		}
	}

	&.failed {
		> div.icon {
			color: #a20202;
			border-color: #d02121;
			background-color: #ffd0d0;
		}

		> div.value {
			> div.title {
				color: #940909;
			}
		}
	}
}
</style>

<script>
export default {
	props: {
		bot: {
			type: Object,
			required: true
		}
	},

	computed: {
		status() {
			return this.bot.status
		},

		icon() {
			switch(this.status) {
				case 'pending':
					return 'far fa-clock'

				case 'running':
					return 'far fa-cog fa-spin'

				case 'completed':
					return 'fas fa-check'

				case 'failed':
					return 'fas fa-remove'
			}
		},

		title() {
			return this.$t(`chatBot.status.${this.status}.title`)
		},

		description() {
			return this.$t(`chatBot.status.${this.status}.description`)
		}
	}
}
</script>
